import React from "react";

const FeatureAndBenefitsRotormixDE = <div className='feature-and-benefits'>
    <h2>Eigenschaften und Nutzen</h2>
    <ul>
        <li>Unterbrechungsfreie Dosierung</li>
        <li>Drehzahlproportionale Förderung</li>
        <li>Ventilloses System</li>
        <li>Förderung abrasiver Medien</li>
        <li>Pulsations- und scherarm</li>
        <li>Hohe Wiederhol- und Dosiergenauigkeit, ± 1 %</li>
        <li>Parametrierbarer Rücksaugeffekt</li>
        <li>Dynamische Dosierung</li>
        <li>Variables Mischungsverhältnis</li>
    </ul>
</div>

const FeatureAndBenefitsRotormixEN = <div className='feature-and-benefits'>
    <h2>Features and Benefits</h2>
    <ul>
        <li>Uninterrupted metering</li>
        <li>Speed proportional conveying</li>
        <li>Valve less system</li>
        <li>Conveying abrasive media</li>
        <li>Pulsation and low shear</li>
        <li>High repeatability and metering accuracy, ± 1 %</li>
        <li>Parameterizable back suction effect</li>
        <li>Dynamic dosing</li>
        <li>Variable mixing ratio</li>
    </ul>
</div>

const FeatureAndBenefitsRotormixFR = <div className='feature-and-benefits'>
    <h2>Propriétés et avantages</h2>
    <ul>
        <li>Débit continu</li>
        <li>Distribution proportionnelle à la vitesse</li>
        <li>Aucune valve de distribution nécéssaire</li>
        <li>Distribution de produits abrasifs</li>
        <li>Faible cisaillement</li>
        <li>Reproductibilité et précision de dosage élevées, ± 1 %</li>
        <li>Effet "happe goutte" paramétrable</li>
        <li>Dosage dynamique</li>
        <li>Rapport de dosage variable</li>
    </ul>
</div>
const TechnicalDataRotormix = (lang: string) => (
    <div className='technical-data'>
        <img src={`./assets/images/technical-data-rotormix_${lang}.jpg`} alt={'Technical Data'}/>
    </div>
)

const OverviewRotormixDE = <div className='technical-data'>
    <img src={'./assets/images/overview-rotormix.png'} alt={'Technical Data'}/>
</div>

const OverviewRotormixEN = <div className='technical-data'>
    <img src={'./assets/images/overview-rotormix.png'} alt={'Technical Data'}/>
</div>

const OverviewRotormixFR = <div className='technical-data'>
    <img src={'./assets/images/overview-rotormix.png'} alt={'Technical Data'}/>
</div>

const RotorInfo1DE = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>1</div>
        <span>Exzenterschneckenpumpe</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container'>
            <img src="./assets/images/rotormix-hs-1.gif" alt="Rotormix"/>
            <p className='copyright'>
                Von Petteri Aimonen - Eigenes Werk, Gemeinfrei, <a
                href="https://commons.wikimedia.org/w/index.php?curid=8112239">https://commons.wikimedia.org/w/index.php?curid=8112239</a>
            </p>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>Produkteigenschaften</h3>
                <ul>
                    <li>Variable Austragsmenge ab 0,002 ml</li>
                    <li>Ausflussrate von 0,1 bis 45 ml/min</li>
                    <li>Drehzahl von 0 bis 150 U/min</li>
                    <li>Maximaler Eingangsdruck von 6 bar</li>
                    <li>Maximaler Betriebsdruck von 10 bar</li>
                    <li>Optimal geeignet für alle Viskositäten von dünnflüssig bis pastös</li>
                    <li>Rücksaugeffekt</li>
                </ul>
            </div>
            <div>
                <h3>Optionen</h3>
                <ul>
                    <li>Kartuschenzuführung 10cc / 30cc / 55cc</li>
                    <li>Druckregler und Niveaukontrolle für Kartuschenzuführung</li>
                    <li>Sprühadapter</li>
                    <li>Antriebsset</li>
                    <li>Verschiedene Dosiernadeln Medium.</li>
                </ul>
            </div>
            <div className='info-item__content__button-container'>
                <button className='more-details-btn'>
                    <a href='https://www.youtube.com/watch?v=TBL-M9pLCNk&ab_channel=DOPAG%E2%80%93Metering%2CMixingandDispensingTechnology'
                       target='_blank' rel='noreferrer'>
                        Mehr Details
                    </a>
                </button>
            </div>
        </div>
    </div>
</div>;

const RotorInfo1EN = <div className='info-item'>
        <div className='info-item__title'>
            <div className='red-circle'>1</div>

            <span>Progressive cavity pump (PCP)</span>
        </div>
        <div className='info-item__content'>
            <div className='info-item__content__image-container'>
                <img src="./assets/images/rotormix-hs-1.gif" alt="Rotormix"/>
                <p className='copyright'>
                    Von Petteri Aimonen - Eigenes Werk, Gemeinfrei, <a
                    href="https://commons.wikimedia.org/w/index.php?curid=8112239">https://commons.wikimedia.org/w/index.php?curid=8112239</a>
                </p>
            </div>
            <div className='info-item__content__text-container'>
                <div>
                    <h3>Product properties</h3>
                    <ul>
                        <li>Variable discharge rate from 0.002 ml</li>
                        <li>Output rate of 0.1 to 45 ml/min</li>
                        <li>Speed of 0 to 150 rev/min</li>
                        <li>Maximum input pressure of 6 bar</li>
                        <li>Maximum operating pressure of 10 bar</li>
                        <li>Ideally suitable for all viscosities from highly fluid to pasty</li>
                        <li>Snuff-back function</li>
                    </ul>
                </div>
                <div>
                    <h3>Options</h3>
                    <ul>
                        <li>Cartridge supply 10cc / 30cc / 55cc</li>
                        <li>Pressure regulator and level control for cartridge supply</li>
                        <li>Spray adaptor</li>
                        <li>Servo controller</li>
                        <li>Various needle tips</li>
                    </ul>
                </div>
                <div className='info-item__content__button-container'>
                    <button className='more-details-btn'>
                        <a href='https://www.youtube.com/watch?v=I30DlQWKKXo&list=PL1IvLP7r_vwk6CAqoyzE1wbvx83cj-ggg&index=3'
                           target='_blank' rel='noreferrer'>
                            More Details
                        </a>
                    </button>
                </div>
            </div>
        </div>
    </div>;
const RotorInfo1FR = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>1</div>
        <span>Pompes à cavité progressive</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container'>
            <img src="./assets/images/rotormix-hs-1.gif" alt="Rotormix"/>
            <p className='copyright'>
                Von Petteri Aimonen - Eigenes Werk, Gemeinfrei,
                <a
                    rel='noreferrer'
                    href="https://commons.wikimedia.org/w/index.php?curid=8112239"
                    target='_blank'>https://commons.wikimedia.org/w/index.php?curid=8112239</a></p>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>Propriétés</h3>
                <ul>
                    <li>A partir de 0,002 ml</li>
                    <li>Débit de 0,1 à 45 ml/min</li>
                    <li>Vitesse de 0 à 150 tr/min</li>
                    <li>Pression d’entrée maximum de 6 bar</li>
                    <li>Pression de distribution maximum de 10 bar</li>
                    <li>Parfaitement adaptées à toutes les viscosités, de produits liquides aux produits pâteux</li>
                    <li>Fonction "happe goutte"</li>
                </ul>
            </div>
            <div>
                <h3>Options</h3>
                <ul>
                    <li>Alimentation par cartouche 10 cc / 30 cc / 55 cc</li>
                    <li>Régulateur de pression et contrôle de niveau bas</li>
                    <li>Adaptateur de pulvérisation</li>
                    <li>Différents types d'aiguille de distribution possible</li>
                </ul>
            </div>
            <div className='info-item__content__button-container'>
                <button className='more-details-btn'>
                    <a href='https://www.youtube.com/watch?v=I30DlQWKKXo&list=PL1IvLP7r_vwk6CAqoyzE1wbvx83cj-ggg&index=3'
                       target='_blank' rel='noreferrer'>
                        Plus de détails
                    </a>
                </button>
            </div>
        </div>
    </div>
    </div>;

export {
    FeatureAndBenefitsRotormixDE,
    FeatureAndBenefitsRotormixEN,
    FeatureAndBenefitsRotormixFR,
    TechnicalDataRotormix,
    OverviewRotormixDE,
    OverviewRotormixEN,
    OverviewRotormixFR,
    RotorInfo1DE,
    RotorInfo1EN,
    RotorInfo1FR
}

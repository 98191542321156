import React from 'react';
import './_app.scss';
import {Header, Home, Model3d, LanguageBar, Footer, StartPage} from "./components";

function App() {
    return (
        <div className="app">
            <Header/>
            <div className='main-wrapper'>
                <StartPage/>
                <Home/>
                <Model3d/>
                <LanguageBar/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;

import React from 'react';
import VStore from "../../store";
import './_control-btn-list.scss';
import {langData} from "../../utils/language";

interface ControlBtnListProps {
    isPlaying: boolean;
    togglePlay: () => void;
    resetFirstState: () => void;
}

export default function ControlBtnList({isPlaying, togglePlay, resetFirstState}: ControlBtnListProps) {
    const {currentPage, currentInfo, language, setCurrentPage, setShowModal, setCurrentInfo} = VStore();

    return (<div className='control-btn-list'>
        <div>
            <button
                onClick={() => {
                    setCurrentPage('home')
                    setCurrentInfo(null)
                }}
            >
                <img src="./assets/images/home.svg" alt="Home"/>
                <span>
                    {
                        langData['home'][language]
                    }
                </span>
            </button>
            <button
                onClick={resetFirstState}
            >
                <img src="./assets/images/reset.svg" alt="Reset"/>
                <span>
                    {
                        langData['back'][language]
                    }
                </span>
            </button>
            {
                currentPage === 'vectomix' && currentInfo === 'vectomix-info-1' ?
                    (isPlaying ?
                            <button
                                onClick={togglePlay}
                            >
                                <img src="./assets/images/stop.svg" alt="Play"/>
                                <span>
                                    {
                                        langData['pause'][language]
                                    }
                                </span>
                            </button> :
                            <button
                                onClick={togglePlay}
                            >
                                <img src="./assets/images/play.svg" alt="Play"/>
                                <span>
                                    {
                                        langData['play'][language]
                                    }
                                </span>
                            </button>
                    ) : ''
            }
            {
                currentInfo ? <button
                    onClick={() => setShowModal(currentInfo)}
                >
                    <img src="./assets/images/info.svg" alt="Information"/>
                    <span>
                        {
                            langData['info'][language]
                        }
                    </span>
                </button> : ''
            }
        </div>
    </div>);
}

const langData: { [key: string] : any} = {
    'homeMainButton': {
        'de': 'Bitte wählen Sie eine Option',
        'en': 'Please select an option',
        'fr': 'Veuillez sélectionner une option'
    },
    'eldomix': {
        'de': 'Zahnrad-Dosieranlagen',
        'en': 'Gear metering systems',
        'fr': 'Dispositifs de dosage volumétrique à pompe à engrenages',
    },
    'rotormix': {
        'de': 'Exzenterschnecken-Dosieranlagen',
        'en': 'Progressive cavity pump systems (PCP)',
        'fr': 'Systèmes de dosage volumétrique à pompe à cavité progressive',
    },
    'vectomix' : {
        'de': 'Kolben-Dosieranlagen',
        'en': 'Piston metering systems',
        'fr': 'Systèmes de dosage volumétrique à pompe à piston',
    },
    'vectomix-btn-1' : {
        'de': 'A. Technische Daten',
        'en': 'A. Technical Data',
        'fr': 'A. Caractéristiques techniques',
    },
    'vectomix-btn-2' : {
        'de': 'B. Eigenschaften und Nutzen',
        'en': 'B. Features and Benefits',
        'fr': 'B. Propriétés et avantages',
    },
    'vectomix-btn-3' : {
        'de': 'C. Modularer Aufbau',
        'en': 'C. Modular Structure',
        'fr': 'C. Conception modulaire',
    },
    'eldomix-btn-1' : {
        'de': 'A. Technische Daten',
        'en': 'A. Technical Data',
        'fr': 'A. Données techniques',
    },
    'eldomix-btn-2' : {
        'de': 'B. Eigenschaften und Nutzen',
        'en': 'B. Features & Benefits',
        'fr': 'B. Caractéristiques et avantages',
    },
    'eldomix-btn-3' : {
        'de': 'C. Modularer Aufbau',
        'en': 'C. Modular Structure',
        'fr': 'C. Conception modulaire',
    },
    'rotormix-btn-1' : {
        'de': 'A. Technische Daten',
        'en': 'A. Technical Data',
        'fr': 'A. Données techniques',
    },
    'rotormix-btn-2' : {
        'de': 'B. Eigenschaften und Nutzen',
        'en': 'B. Features & Benefits',
        'fr': 'B. Caractéristiques et avantages',
    },
    'rotormix-btn-3' : {
        'de': 'C. Übersicht Aufbau Rotordis',
        'en': 'C. Overview of Rotordis Structure',
        'fr': 'C. Aperçu de la structure Rotordis',
    },
    'start-title': {
        'de': 'Die richtige Dosiertechnologie für jede Anwendung!',
        'en': 'The right metering technology for every application!',
        'fr': 'La bonne technologie de dosage pour chaque application !',
    },
    'start': {
        'de': 'Start',
        'en': 'Start',
        'fr': 'Démarrage',
    },
    'home': {
        'de': 'Übersicht',
        'en': 'Home',
        'fr': 'Page d\'accueil',
    },
    'play': {
        'de': 'Abspielen',
        'en': 'Play',
        'fr': 'Jouer',
    },
    'pause': {
        'de': 'Pause',
        'en': 'Pause',
        'fr': 'Pause',
    },
    'back': {
        'de': 'Zurück',
        'en': 'Back',
        'fr': 'Retour',
    },
    'info': {
        'de': 'Info',
        'en': 'Info',
        'fr': 'Info',
    },
}

export {langData}

import React from 'react';
import './_modal-list.scss';
import VStore from "../../store";
import {Modal} from "../index";
import * as Vectomix from './vectomix/vectomix'
import * as Eldomix from './eldomix/eldomix'
import * as Rotormix from './rotormix/rotormix'

export default function ModalList() {
    const {showModal, language} = VStore();

    return (<div className="modal-list">
        <Modal show={showModal === 'TechnicalDataVectomix'}
               children={Vectomix.TechnicalDataVectomix(language)}
        />
        <Modal show={showModal === 'TechnicalDataEldomix'}
               children={Eldomix.TechnicalDataEldomix(language)}
        />
        <Modal show={showModal === 'TechnicalDataRotormix'}
               children={Rotormix.TechnicalDataRotormix(language)}
        />
        <Modal show={showModal === 'FeatureAndBenefitsVectomix'}
               children={language === 'de' ? Vectomix.FeatureAndBenefitsVectomixDE : (language === 'en' ? Vectomix.FeatureAndBenefitsVectomixEN : Vectomix.FeatureAndBenefitsVectomixFR)}
        />
        <Modal show={showModal === 'FeatureAndBenefitsEldomix'}
               children={language === 'de' ? Eldomix.FeatureAndBenefitsEldomixDE : (language === 'en' ? Eldomix.FeatureAndBenefitsEldomixEN : Eldomix.FeatureAndBenefitsEldomixFR)}
        />
        <Modal show={showModal === 'FeatureAndBenefitsRotormix'}
               children={language === 'de' ? Rotormix.FeatureAndBenefitsRotormixDE : (language === 'en' ? Rotormix.FeatureAndBenefitsRotormixEN : Rotormix.FeatureAndBenefitsRotormixFR)}
        />
        <Modal show={showModal === 'ModuleVectomix'}
               children={language === 'de' ? Vectomix.ModuleVectomixDE : (language === 'en' ? Vectomix.ModuleVectomixEN : Vectomix.ModuleVectomixFR)}
        />
        <Modal show={showModal === 'OverviewRotormix'}
               children={language === 'de' ? Rotormix.OverviewRotormixDE : (language === 'en' ? Rotormix.OverviewRotormixEN : Rotormix.OverviewRotormixFR)}
        />
        <Modal show={showModal === 'vectomix-info-1'}
               children={language === 'de' ? Vectomix.VectomixInfo1DE : (language === 'en' ? Vectomix.VectomixInfo1EN : Vectomix.VectomixInfo1FR)}
        />
        <Modal show={showModal === 'vectomix-info-2'}
               children={language === 'de' ? Vectomix.VectomixInfo2DE : (language === 'en' ? Vectomix.VectomixInfo2EN : Vectomix.VectomixInfo2FR)}
        />
        <Modal show={showModal === 'vectomix-info-3'}
               children={language === 'de' ? Vectomix.VectomixInfo3DE : (language === 'en' ? Vectomix.VectomixInfo3EN : Vectomix.VectomixInfo3FR)}
        />
        <Modal show={showModal === 'eldomix-info-1'}
               children={language === 'de' ? Eldomix.EldomixInfo1DE : (language === 'en' ? Eldomix.EldomixInfo1EN : Eldomix.EldomixInfo1FR)}
        />
        <Modal show={showModal === 'eldomix-info-2'}
               children={language === 'de' ? Eldomix.EldomixInfo2DE : (language === 'en' ? Eldomix.EldomixInfo2EN : Eldomix.EldomixInfo2FR)}
        />
        <Modal show={showModal === 'eldomix-info-3'}
               children={language === 'de' ? Eldomix.EldomixInfo3DE : (language === 'en' ? Eldomix.EldomixInfo3EN : Eldomix.EldomixInfo3FR)}
        />
        <Modal show={showModal === 'rotormix-info-1'}
               children={language === 'de' ? Rotormix.RotorInfo1DE : (language === 'en' ? Rotormix.RotorInfo1EN : Rotormix.RotorInfo1FR)}
        />
    </div>)
        ;
}

import React from 'react';
import './_home.scss';
import VStore from "../../store";
import {langData} from "../../utils/language";

const ITEM_LIST = [
    {
        id: 'vectomix',
        imageSrc: './assets/images/vectomix.jpg',
        imageTitleSrc: "./assets/images/vectomix_title.jpg",
    },
    {
        id: 'eldomix',
        imageSrc: './assets/images/eldomix.jpg',
        imageTitleSrc: "./assets/images/eldomix_title.jpg",
    },
    {
        id: 'rotormix',
        imageSrc: './assets/images/rotormix.jpg',
        imageTitleSrc: "./assets/images/rotormix_title.jpg",
    },
];

export default function Home() {
    const {language,currentPage, setCurrentPage} = VStore();

    return (<div className={"home page" + (currentPage === 'home' ? ' show' : '')}>
        <div className='title'>
            <div>
                {
                    langData['homeMainButton'][language]
                }
            </div>
        </div>
        <div className='item-list'>
            {
                ITEM_LIST.map((item, index) => {
                    return <div key={index} className='item'>
                        {/*<div className='item__title'>
                            <img src={item.imageTitleSrc} alt="Item Title"/>
                        </div>*/}
                        <div className='item__img'
                             onClick={() => setCurrentPage(item.id)}
                        >
                            <img src={item.imageSrc} alt="Item"/>
                        </div>
                        <button className='item__btn'
                                onClick={() => setCurrentPage(item.id)}
                        >
                            {
                                langData[item.id][language]
                            }
                        </button>
                    </div>
                })
            }
        </div>
    </div>);
}

import VStore from "../../store";
import './_model3d.scss';
import {langData} from "../../utils/language";
import {ControlBtnList, InfoBtnList, ModalList} from "../index";
import {useEffect, useRef, useState} from "react";


export default function Model3d() {
    const {currentPage, language, setShowModal, setCurrentInfo} = VStore();

    const [isSceneLoaded, setIsSceneLoaded] = useState(false);

    const [isPlaying, setIsPlaying] = useState(true);

    const Iframe3D = useRef<HTMLIFrameElement>(null);

    const timeOutInstance = useRef<null | NodeJS.Timeout>(null);

    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.data === 'sceneLoaded') {
                //console.log(Iframe3D.current?.contentWindow.v3d.puzzles.procedures)
                setIsSceneLoaded(true);
            } else if (event.data.type === 'show-info') {
                if (timeOutInstance.current) {
                    clearTimeout(timeOutInstance.current);
                }
                timeOutInstance.current = setTimeout(() => {
                    setShowModal(event.data.target)
                    setCurrentInfo(event.data.target)
                    timeOutInstance.current = null;
                }, 1000);
            }
        })
    }, [])

    useEffect(() => {
        if (isSceneLoaded) {
            /*setIsTouch(true);*/
            if (currentPage !== 'home') {
                //@ts-ignore
                Iframe3D.current?.contentWindow.v3d.puzzles.procedures.loadScene(currentPage)
            }
        }

        if (currentPage === 'vectomix') {
            setIsPlaying(true);
        }

    }, [isSceneLoaded, currentPage])

    function resetFirstState() {
        //@ts-ignore
        Iframe3D.current?.contentWindow.v3d.puzzles.procedures.resetState(currentPage);
        setCurrentInfo(null);
    }

    function togglePlay() {
        //@ts-ignore
        Iframe3D.current?.contentWindow.v3d.puzzles.procedures.toggleAnimation(!isPlaying);
        setIsPlaying(!isPlaying);
    }

    return (<div className={"model3d page" + (currentPage !== 'home' && currentPage !== 'start-page' ? ' show' : '')}>
        <div className='model-title'>
            <span>
                {
                    currentPage !== 'home' && currentPage !== 'start-page' ? langData[currentPage][language] : ''
                }
            </span>
        </div>
        <div className='model-content'>
            <div className='iframe-container'>
                <iframe src="./assets/3d-models/model/index.html" allowFullScreen={true} title='Vectomix' ref={Iframe3D}/>
                <ControlBtnList resetFirstState={resetFirstState} togglePlay={togglePlay} isPlaying={isPlaying}></ControlBtnList>
            </div>
            <InfoBtnList/>
        </div>
        <ModalList/>
    </div>);
}

import {create} from "zustand";

interface VStoreState {
    language: 'en' | 'de' | 'fr';
    currentPage: string;
    showModal: string | null;
    currentInfo: string | null;

    setCurrentPage: (page: string) => void;
    setLanguage: (lang: 'en' | 'de' | 'fr') => void;
    setShowModal: (modal: string | null) => void;
    setCurrentInfo: (info: string | null) => void;
}

const VStore = create<VStoreState>((set) => ({
    currentPage: "start-page",
    language: "de",
    showModal: null,
    currentInfo: null,

    setCurrentPage: (page) => set({currentPage: page}),
    setLanguage: (lang) => set({language: lang}),
    setShowModal: (modal) => set({showModal: modal}),
    setCurrentInfo: (info) => set({currentInfo: info}),
}));

export default VStore;

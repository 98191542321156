import React from "react";

const FeatureAndBenefitsEldomixDE = <div className='feature-and-benefits'>
    <h2>Eigenschaften und Nutzen</h2>
    <ul>
        <li>Modularer, flexibler Aufbau ermöglicht breites Einsatzgebiet, variables Mischungsverhältnis und variable
            Austragsmenge
        </li>
        <li>Kompakte Tischversion bei der eldomix100</li>
        <li>Einfache und komfortable Bedienung</li>
        <li>Materialaustrag schussweise oder kontinuierlich</li>
        <li>Über-/Unterdruckabsicherung</li>
        <li>Spülmittelfrei</li>
    </ul>
</div>
const FeatureAndBenefitsEldomixEN = <div className='feature-and-benefits'>
    <h2>Features and Benefits</h2>
    <ul>
        <li>Modular, flexible design enables a wide range of applications, variable mixing ratio and variable discharge
            quantity
        </li>
        <li>Compact table-top version for the eldomix100</li>
        <li>Simple and convenient operation</li>
        <li>Material discharge in batches or continuously</li>
        <li>Over/underpressure protection</li>
        <li>Detergent-free</li>
    </ul>

</div>
const FeatureAndBenefitsEldomixFR = <div className='feature-and-benefits'>
    <h2>Caractéristiques et avantages</h2>
    <ul>
        <li>La structure, modulaire et flexible, permet de répondre à de nombreuses applications de distribution, avec
            des rapports de dosage et débits variables.
        </li>
        <li>Version compacte, de table pour l'eldomix100</li>
        <li>Utilisation simple et pratique</li>
        <li>Distribution en mode "Dose" ou en mode "Débit continu"</li>
        <li>Contrôle des pressions de travail et alarmes</li>
        <li>Utilisation de mélangeur jetable, pas de rinçage</li>
    </ul>
</div>
const TechnicalDataEldomix = (lang: string) => <div className='technical-data'>
    <img src={`./assets/images/technical-data-eldomix_${lang}.jpg`} alt={'Technical Data'}/>
</div>
const EldomixInfo1DE = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>1</div>

        <span>Auslass-System</span>
    </div>
    <div className='info-item__content'>
        {/*<div className='info-item__content__image-container'>
            <img src="./assets/images/eldomix-info-1.jpg" alt=""/>
        </div>*/}
        <div className='info-item__content__text-container'>
            <div>
                <h3>Merkmale</h3>
                <span>Die eldomix wird über einen Dosierrechner mit einer Touchscreen-Anlagenbedienung gesteuert und verfügt als Auslass-System über ein statisches Mischsystem, bestehend aus einem 2K-Ventil in der 100er Baureihe, und einem Doppelrücksaugventil in der 600er Baureihe, in Kombination mit einem statischen Kunststoff-Mischrohr. Das Mischrohr kann nach Aushärten des Materials kostengünstig entsorgt werden. Ein aufwändiges und umweltbelastendes Spülen entfällt.</span>
            </div>
            <div>
                <h3>Vorteile</h3>
                <span>Statisch-dynamische Mischsysteme stellen eine Synthese aus rein statischen und rein dynamischen Mischsystemen dar. Sie verbinden den Vorteil des Einwegkunststoffmischrohres mit der Möglichkeit des hohen Mischenergieeintrages in das Medium.</span>
            </div>
        </div>
    </div>
</div>;
const EldomixInfo1EN = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>1</div>

        <span>Dispensing system</span>
    </div>
    <div className='info-item__content'>
        {/*<div className='info-item__content__image-container'>
            <img src="./assets/images/eldomix-info-1.jpg" alt=""/>
        </div>*/}
        <div className='info-item__content__text-container'>
            <div>
                <h3>Features</h3>
                <span>The eldomix is fitted with a metering computer with touch screen terminal and a static mixing system that includes a 2K valve combined with a disposable static mixer. Should mixed material be allowed to cure in the mixer, it can be replaced simply and inexpensively, thus avoiding a time consuming, expensive and environmentally damaging flushing cycle.</span>
            </div>
            <div>
                <h3>Advantages</h3>
                <span>Static-dynamic mixing systems represent a synthesis of purely static and purely dynamic mixing systems. They combine the advantage of the disposable plastic mixing tube with the possibility of high mixing energy input into the medium.</span>
            </div>
        </div>
    </div>
</div>;
const EldomixInfo1FR = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>1</div>
        <span>Système de distribution</span>
    </div>
    <div className='info-item__content'>
        {/*<div className='info-item__content__image-container'>
            <img src="./assets/images/eldomix-info-1.jpg" alt=""/>
        </div>*/}
        <div className='info-item__content__text-container'>
            <div>
                <h3>Fonctions</h3>
                <span>L'eldomix est pilotée par un automate et communique via un écran tactile. En standard, la distribution est assurée par une valve bi-composant combinée avec un mélangeur statique plastique. L’utilisation de mélangeurs jetables évite l’emploi de solvant de rinçage au poste de travail et participe également à limiter les pertes produits liées à cette opération.</span>
            </div>
            <div>
                <h3>Option</h3>
                <span>Les systèmes de mélange "statico-dynamique" constituent une alternative aux mélangeurs classiques statiques et  aux mélangeurs dynamiques. Ils combinent les avantages du mélangeur en plastique jetable et la rotation des éléments de mélange.</span>
            </div>
        </div>
    </div>
</div>;
const EldomixInfo2DE = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>2</div>
        <span>Zahnradpumpe</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container b-img'>
            <img src="./assets/images/eldomix-info-1.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>Eigenschaften</h3>
                <ul>
                    <li>Vielfältige Einsatzmöglichkeiten dank verschiedener Pumpengrößen, Spielklassen und
                        Dichtsysteme
                    </li>
                    <li>Hochwertiger und gehärteter Stahl ermöglicht den Einsatz auch unter erschwerten Bedingungen.
                    </li>
                    <li>Spezielle Beschichtung zum Schutz vor überhöhtem Verschleiß und für eine längere
                        Lebensdauer
                    </li>
                    <li>Dosiereinheit optional mit Zahnrad-Durchflussmesszelle verfügbar</li>
                    <li>Sonderausführungen für spezielle Anwendungen</li>
                </ul>
            </div>
            <div>
                <h3>
                    Dichtsysteme
                </h3>
                <span>Die Zahnraddosierpumpe kann mit einem von vier möglichen Dichtsystemen ausgestattet werden. Die Dichtsysteme sind modular aufgebaut, ein Austausch ist möglich.</span>
                <ol>

                    <li><b>URF-Hochdruckdichtung:</b> Diese Dichtungsvariante gewährleistet bei sehr hochviskosen
                        Medien einen optimalen Füllungsgrad durch einen möglichst hohen Eingangsdruck der Pumpe.
                    </li>
                    <li><b>VCR-Dachmanschette:</b> Dieses Dichtungssystem kommt immer dann zum Einsatz, wenn die
                        Materialen einen hohen Anteil an Füllstoffen aufweisen und wenn eine hohe chemische
                        Beständigkeit gefordert ist.
                    </li>
                    <li><b>RSS-3-fach Wellendichtring:</b> Bei einem Großteil der Anwendungen hat sich diese
                        kostengünstige Dichtungsvariante bewährt. Mit ihr lässt sich eine große Bandbreite an
                        Anforderungen abdecken.
                    </li>
                    <li><b>MSE-Gleitringdichtung:</b> Diese anspruchsvolle Dichtung ist vor allem bei hochgefüllten
                        Materialien geeignet, da sie besonders verschleißarm ist.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>;
const EldomixInfo2EN = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>2</div>
        <span>Gear pump</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container b-img'>
            <img src="./assets/images/eldomix-info-1.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>Product properties</h3>
                <ul>
                    <li>Versatile use thanks to different pump sizes, clearance classes and seal systems.</li>
                    <li>High-quality, hardened steel ensures operation even under difficult conditions.</li>
                    <li>Special coating for protection against excessive wear and to ensure a longer service life</li>
                    <li>Meter optionally available with gear flow meter</li>
                    <li>Special versions available for special applications</li>
                </ul>
            </div>
            <div>
                <h3>
                    Seal systems
                </h3>
                <span>The gear metering pump can be fitted with one of four different seal systems. The seal systems have a modular design and can be replaced.</span>
                <ol>
                    <li><b>URF high-pressure seal: </b> This seal type ensures opti- mum fill level with highly viscous media by keeping the input pressure of the pump as high as possible.</li>
                    <li><b>VCR sealing sleeve: </b> This seal system is always used when the materials have a high proportion of fillers and when high chemical resistance is required.</li>
                    <li><b>RSS 3-fold shaft seal: </b> This cost-effective seal type is used to good effect in a large number of applications. It can be used to cover a wide range of requirements.</li>
                    <li><b>MSE mechanical seal: </b> This complex seal is particularly suitable for highly filled materials, as it is extremely low-wear.</li>
                </ol>
            </div>
        </div>
    </div>
</div>;
const EldomixInfo2FR = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>2</div>
        <span>Pompes de dosage à engrenages</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container b-img'>
            <img src="./assets/images/eldomix-info-1.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>Caractéristiques</h3>
                <ul>
                    <li>Grande polyvalence grâce aux différentes tailles de pompes, différentes classes de jeu interne et aux différents systèmes d’étanchéité</li>
                    <li>Acier trempé de haute qualité permettant un fonctionnement même dans des conditions difficiles</li>
                    <li>Traitement spécial offrant une protection contre l’usure et garantissant une plus longue durée de vie</li>
                    <li>En option : Contrôle du rapport de dosage par volucompteurs</li>
                    <li>Versions spéciales disponibles pour les produits particuliers</li>
                </ul>
            </div>
            <div>
                <h3>
                    Etanchéité
                </h3>
                <span>Les pompes de dosage à engrenages peuvent être équipées de quatre dispositifs d’étanchéité différents. Ces dispositifs bénéficie d'une conception modulaire et peuvent être facilement remplacés.</span>
                <ol>
                    <li><b>Joint haute pression URF:</b> Ce type de joint garantit une alimentation produit optimale avec des produits de haute viscosité en maintenant la pression d’entrée de la pompe aussi élevée que possible.</li>
                    <li><b>Dispositif d’étanchéité VCR:</b> Ce dispositif est systématiquement utilisé lors de la mise en oeuvre de produits fortement chargés et lorsqu’une résistance élevée aux agents chimiques est requise.</li>
                    <li><b>Garniture RSS:</b> Ce type de joint est utilisé de façon efficace pour de nombreuses applications. Il permet de répondre à des exigences très diverses.</li>
                    <li><b>Joint mécanique MSE:</b> Cette typologie de joint est tout particulièrement adaptée aux produits à forte teneur en charges car il s’use très peu.</li>
                </ol>
            </div>
        </div>
    </div>
</div>;
const EldomixInfo3DE = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>3</div>
        <span>Drucktanks</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container'>
            <img src="./assets/images/eldomix-info-3.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>
                    Eigenschaften
                </h3>
                <ul>
                    <li>Für dünnflüssige Medien wie Farben, Öle und Konservierungsmittel</li>
                    <li>Modularer Aufbau</li>
                    <li>Eingangsdruck max. 6 bar</li>
                    <li>Abgang unten mit Kugelhahn</li>
                    <li>Max. Viskosität ca 80.000 mPas</li>
                </ul>
            </div>
            <div>
                <h3>
                    Optionen
                </h3>
                <ul>
                    <li>Luftwartungseinheit</li>
                    <li>Materialfilter am Ausgang</li>
                    <li>Rührwerk</li>
                    <li>Füllstandsonden</li>
                    <li>Heizmanschette</li>
                    <li>Schauglas</li>
                </ul>
            </div>
        </div>
    </div>
</div>;
const EldomixInfo3EN = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>3</div>
        <span>Pressure tanks</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container'>
            <img src="./assets/images/eldomix-info-3.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>
                    Features
                </h3>
                <ul>
                    <li>For low viscosity media such as paint, oils and preservatives</li>
                    <li>Modular construction</li>
                    <li>Input pressure max. 6 bar</li>
                    <li>Bottom outlet with ball valve</li>
                    <li>Max. viscosity 80'000 mPas</li>
                </ul>
            </div>
            <div>
                <h3>Options</h3>
                <ul>
                    <li>Air maintenance device</li>
                    <li>Material filter on outlet</li>
                    <li>Agitator</li>
                    <li>Fill level sensors</li>
                    <li>Heating sleeve</li>
                    <li>Gauge glass</li>
                </ul>
            </div>
        </div>
    </div>
</div>;
const EldomixInfo3FR = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>3</div>
        <span>Réservoirs sous pression</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container'>
            <img src="./assets/images/eldomix-info-3.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>
                    Propriétés
                </h3>
                <ul>
                    <li>Destinés aux produits ﬂuides tels que les huiles, les polyuréthanes, les epoxys, les silicones, ...</li>
                    <li>Construction modulaire</li>
                    <li>Pression d’entrée max. 6 bars</li>
                    <li>Sortie par le fond et équipés de vannes manuelle</li>
                    <li>Viscosité max. 80 000 mPas</li>
                </ul>
            </div>
            <div>
                <h3>Options</h3>
                <ul>
                    <li>Régulateur de pression d’air</li>
                    <li>Filtre produit en sortie</li>
                    <li>Agitateur</li>
                    <li>Sondes de niveau bas</li>
                    <li>Ceinture chauffante</li>
                    <li>Manomètre de contrôle de pression</li>
                </ul>
            </div>
        </div>
    </div>
</div>;

export {
    FeatureAndBenefitsEldomixDE,
    FeatureAndBenefitsEldomixEN,
    FeatureAndBenefitsEldomixFR,
    TechnicalDataEldomix,
    EldomixInfo1DE,
    EldomixInfo1EN,
    EldomixInfo1FR,
    EldomixInfo2DE,
    EldomixInfo2EN,
    EldomixInfo2FR,
    EldomixInfo3DE,
    EldomixInfo3EN,
    EldomixInfo3FR
}


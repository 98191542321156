import React from "react";

const FeatureAndBenefitsVectomixDE = <div className='feature-and-benefits'>
    <h2>Eigenschaften und Nutzen</h2>
    <ul>
        <li>Modularer Aufbau ermöglicht ein breites Einsatzgebiet, variable Mischungsverhältnisse und Austragsmengen
        </li>
        <li>Höchste Präzision durch Dosieren und Mischen an der Applikationsstelle</li>
        <li>Mehrere Schüsse mit einem Kolbenvolumen ermöglichen kurze Zykluszeiten</li>
        <li>
            Hohe Prozesssicherheit durch
            <ul>
                <li>Totraumarme Konstruktion</li>
                <li>Entlüftung am obersten Punkt der Dosierkammer</li>
                <li>Drucküberwachung in der Dosierkammer</li>
            </ul>
        </li>
        <li>
            Optimierung des Wartungsaufwands durch
            <ul>
                <li>Speziell geführte Dosierkolben</li>
                <li>Spielausgleichende Kupplung zwischen Antrieb und Dosierkolben</li>
                <li>Hermetische Abdichtung des Kolbens mit zusätzlicher Sperrflüssigkeit</li>
            </ul>
        </li>
    </ul>
</div>

const FeatureAndBenefitsVectomixEN = <div className='feature-and-benefits'>
    <h2>Features and Benefits</h2>
    <ul>
        <li>Modular construction enables a wide range of use, variable mixing ratios and flow rates</li>
        <li>High precision through metering and mixing at the point of application</li>
        <li>
            High process security through:
            <ul>
                <li>Construction with reduced dead space</li>
                <li>de-airing on the top of the metering chamber</li>
                <li>metering chamber with pressure monitoring</li>
            </ul>
        </li>
        <li>
            Optimization of the maintenance through:
            <ul>
                <li>specially guided metering pistons</li>
                <li>coupling between drive and metering piston</li>
                <li>hermetic sealing of the piston with additional sealing liquid</li>
            </ul>
        </li>
    </ul>
</div>

const FeatureAndBenefitsVectomixFR = <div className='feature-and-benefits'>
    <h2>Propriétés et avantages</h2>
    <ul>
        <li>La construction modulaire permet un large champ d’application, le réglage d’un rapport de dosage variable
            ainsi que la distribution d’une quantité de doses finales différentes
        </li>
        <li>Précision maximale grâce à la réalisation du dosage et du mélange au plus près du point de coulée</li>
        <li>
            Production sécurisée grâce à:
            <ul>
                <li>une conception compacte</li>
                <li>une purge produit aisée</li>
                <li>une surveillance en continu des pressions de travail</li>
            </ul>
        </li>
        <li>
            Opérations de maintenance réduites grâce à:
            <ul>
                <li>un guidage optimal des pistons doseurs</li>
                <li>un accouplement moteur/piston robuste</li>
                <li>la présence d’un liquide d’inertage</li>
            </ul>
        </li>
    </ul>

</div>

const TechnicalDataVectomix = (lang: string) => <div className='technical-data'>
    <img src={`./assets/images/technical-data-vectomix_${lang}.jpg`} alt={'Technical Data'}/>
</div>

const VectomixInfo1DE = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>1</div>

        <span>Kolbenpumpe</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__text-container'>
            <ul>
                <li>
                    Merkmale
                    <ul>
                        <li>Modularer Aufbau</li>
                        <li>Separate Servoantriebe</li>
                        <li>Steuerung der Austragsmenge</li>
                        <li>Flexibel</li>
                        <li>Erhöhte Verschleißfestigkeit</li>
                    </ul>
                </li>
                <li>
                    Vorteile
                    <ul>
                        <li>Für eine außergewöhnlich lange Lebensdauer.</li>
                        <li>Für deutlich reduzierte TCO.</li>
                        <li>Für hohe Flexibilität.</li>
                        <li>Für hohe Präzision.</li>
                        <li>Für eine breite Palette von Anwendungen.</li>
                        <li>Für eine einfache und intuitive Bedienung.</li>
                        <li>Für alle gängigen Materialien.</li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</div>;

const VectomixInfo1EN = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>1</div>

        <span>Piston pump</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__text-container'>
            <ul>
                <li>
                    Features
                    <ul>
                        <li>Modular design</li>
                        <li>Separate servo drives</li>
                        <li>Discharge rate control</li>
                        <li>Flexibility</li>
                        <li>Increased wear resistance</li>
                    </ul>
                </li>
                <li>
                    Benefits
                    <ul>
                        <li>For exceptionally long service life.</li>
                        <li>For significant reduced TCO.</li>
                        <li>For high flexibility.</li>
                        <li>For high precision.</li>
                        <li>For a wide range of applications.</li>
                        <li>For easy and intuitive operation.</li>
                        <li>For all common materials.</li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
</div>;

const VectomixInfo1FR = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>1</div>

        <span>Pompe à piston</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__text-container'>
            <ul>
                <li>
                    Caractéristiques
                    <ul>
                        <li>Conception modulaire</li>
                        <li>Vérins électriques séparés</li>
                        <li>Contrôle de la quantité distribuée</li>
                        <li>Flexibilité</li>
                        <li>Résistance accrue à l'usure</li>
                    </ul>
                </li>
                <li>
                    Avantages
                    <ul>
                        <li>Durée de vie exceptionnellement longue</li>
                        <li>Réduction significative du TCO</li>
                        <li>Grande flexibilité</li>
                        <li>Grande précision</li>
                        <li>Large gamme d'applications</li>
                        <li>Utilisation facile et intuitive</li>
                        <li>Pour tous les produits courants</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>;

const VectomixInfo2DE = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>2</div>
        <span>Doppel-Fasspumpe</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container'>
            <img src="./assets/images/vectomix-info-2.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>
                    Modularisierung
                </h3>
                <span>
                    Der modulare Aufbau der Fass-und Behälterpumpe bietet zahlreiche Kombinationsmöglichkeiten. Je nach Materialeigenschaften, Gebindedimension und gewünschten Funktionalitäten können zu der Standardausführung noch zahlreiche Optionen zusätzlich ausgewählt werden.
                </span>
            </div>
            <div>
                <h3>
                    Standardausstattung
                </h3>
                <ul>
                    <li>Steuerungseinheit</li>
                    <li>Antriebszylinder</li>
                    <li>Folgeplatte Kolbenpumpe mit Doppelhub</li>
                    <li>Heber</li>
                    <li>Chassis</li>
                </ul>
            </div>
            <div>
                <h3>
                    Optionen
                </h3>
                <ul>
                    <li>Anschlussblock</li>
                    <li>Materialfilter</li>
                    <li>Mischblock</li>
                    <li>Fasseinrollhilfe</li>
                </ul>
            </div>
        </div>
    </div>
</div>;

const VectomixInfo2EN = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>2</div>
        <span>Tandem barrel pump</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container'>
            <img src="./assets/images/vectomix-info-2.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>
                    Modularization
                </h3>
                <span>
                    The modular design of the drum and barrel pumps offers numerous combination options. Depending on the material properties, barrel dimensions and desired functionalities, numerous options can be selected in addition to the standard version.
                </span>
            </div>
            <div>
                <h3>
                    Standard equipment
                </h3>
                <ul>
                    <li>Control unit</li>
                    <li>Drive cylinder</li>
                    <li>Follower plate piston pump with double stroke</li>
                    <li>Lifter</li>
                    <li>Chassis</li>
                </ul>
            </div>
            <div>
                <h3>
                    Options
                </h3>
                <ul>
                    <li>Connection block</li>
                    <li>Material filter</li>
                    <li>Mixing block</li>
                    <li>Drum rolling aid</li>
                </ul>
            </div>
        </div>
    </div>
</div>;

const VectomixInfo2FR = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>2</div>
        <span>Pompe vide-fûts tandem</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container'>
            <img src="./assets/images/vectomix-info-2.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>
                    Modularisation
                </h3>
                <span>
                    La conception modulaire des pompes à plateau suiveur offre de nombreuses possibilités de combinaison. En fonction des propriétés des produits, des dimensions des fûts et des fonctionnalités souhaitées, de nombreuses options peuvent être sélectionnées en plus de la version standard.
                </span>
            </div>
            <div>
                <h3>
                    Equipement standard
                </h3>
                <ul>
                    <li>Coffret de commande</li>
                    <li>Vérin d'entraînement</li>
                    <li>Plateau suiveur et Pompe à piston double effet</li>
                    <li>Elevateur</li>
                    <li>Châssis</li>
                </ul>
            </div>
            <div>
                <h3>
                    Options
                </h3>
                <ul>
                    <li>Bloc de connexion</li>
                    <li>Filtre produits</li>
                    <li>Aide à la mise en place des fûts et tonnelets</li>
                </ul>
            </div>
        </div>
    </div>
</div>;

const VectomixInfo3DE = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>3</div>
        <span>Drucktanks</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container w-40'>
            <img src="./assets/images/vectomix-info-3.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>
                    Eigenschaften
                </h3>
                <ul>
                    <li>Für dünnflüssige Medien wie Farben, Öle und Konservierungsmittel</li>
                    <li>Modularer Aufbau</li>
                    <li>Eingangsdruck max. 6 bar</li>
                    <li>Abgang unten mit Kugelhahn</li>
                    <li>Max. Viskosität ca 80.000 mPas</li>
                </ul>
            </div>
            <div>
                <h3>
                    Optionen
                </h3>
                <ul>
                    <li>Luftwartungseinheit</li>
                    <li>Materialfilter am Ausgang</li>
                    <li>Rührwerk</li>
                    <li>Füllstandsonden</li>
                    <li>Heizmanschette</li>
                    <li>Schauglas</li>
                </ul>
            </div>
        </div>
    </div>
</div>;

const VectomixInfo3EN = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>3</div>
        <span>Pressure tanks</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container w-40'>
            <img src="./assets/images/vectomix-info-3.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>Features</h3>
                <ul>
                    <li>For low viscosity media such as paint, oils and preservatives</li>
                    <li>Modular construction</li>
                    <li>Input pressure max. 6 bar</li>
                    <li>Bottom outlet with ball valve</li>
                    <li>Max. viscosity 80'000 mPas</li>
                </ul>
            </div>
            <div>
                <h3>Options</h3>
                <ul>
                    <li>Air maintenance device</li>
                    <li>Material filter on outlet</li>
                    <li>Agitator</li>
                    <li>Fill level sensors</li>
                    <li>Heating sleeve</li>
                    <li>Gauge glass</li>
                </ul>
            </div>
        </div>
    </div>
</div>;

const VectomixInfo3FR = <div className='info-item'>
    <div className='info-item__title'>
        <div className='red-circle'>3</div>
        <span>Réservoirs sous pression</span>
    </div>
    <div className='info-item__content'>
        <div className='info-item__content__image-container w-40'>
            <img src="./assets/images/vectomix-info-3.jpg" alt=""/>
        </div>
        <div className='info-item__content__text-container'>
            <div>
                <h3>Propriétés</h3>
                <ul>
                    <li>Destinés aux produits ﬂuides tels que les huiles, les polyuréthanes, les époxys, les silicones, ...</li>
                    <li>Construction modulaire</li>
                    <li>Pression d’entrée max. 6 bars</li>
                    <li>Sortie par le fond et équipés de vannes manuelle</li>
                    <li>Viscosité max. 80 000 mPa.s</li>
                </ul>
            </div>
            <div>
                <h3>Options</h3>
                <ul>
                    <li>Régulateur de pression d’air</li>
                    <li>Filtre produit en sortie</li>
                    <li>Agitateur</li>
                    <li>Sondes de niveau bas</li>
                    <li>Ceinture chauffante</li>
                    <li>Manomètre de contrôle de pression</li>
                </ul>
            </div>
        </div>
    </div>
</div>;

const ModuleVectomixDE = <div className='technical-data'>
    <img src={'./assets/images/vectomix_modularer_de.jpg'} alt={'Module'}/>
</div>
const ModuleVectomixEN = <div className='technical-data'>
    <img src={'./assets/images/vectomix_modularer_en.jpg'} alt={'Module'}/>
</div>
const ModuleVectomixFR = <div className='technical-data'>
    <img src={'./assets/images/vectomix_modularer_fr.jpg'} alt={'Module'}/>
</div>

export {
    FeatureAndBenefitsVectomixDE,
    FeatureAndBenefitsVectomixEN,
    FeatureAndBenefitsVectomixFR,
    TechnicalDataVectomix,
    VectomixInfo1EN,
    VectomixInfo1DE,
    VectomixInfo1FR,
    VectomixInfo2DE,
    VectomixInfo2EN,
    VectomixInfo2FR,
    VectomixInfo3DE,
    VectomixInfo3EN,
    VectomixInfo3FR,
    ModuleVectomixDE,
    ModuleVectomixEN,
    ModuleVectomixFR
}

import React from 'react';
import './_start-page.scss';
import VStore from "../../store";
import {langData} from "../../utils/language";


export default function StartPage() {
    const {language, currentPage, setCurrentPage} = VStore();

    const StartPageDE = <>
        <div className='start-page__title'>
            <h2>
                Für jede Anwendung die richtige Dosiertechnik!
            </h2>
        </div>
        <div className='start-page__content'>
            <span>DOPAG liefert sowohl Komponenten als auch hochautomatisierte 1K- und 2K-Dosieranlagen für </span>
            <ul>
                <li>Fetten,</li>
                <li>Kleben,</li>
                <li>Abdichten,</li>
                <li>Vergießen und</li>
                <li>Dichten.</li>
            </ul>
        </div>
        <div className='start-page__start-btn-container'>
            <button
                onClick={() => setCurrentPage('home')}
            >
                Start
            </button>
        </div>
    </>

    const StartPageEN = <>
        <div className='start-page__title'>
            <h2>
                The right metering technology for every application!
            </h2>
        </div>
        <div className='start-page__content'>
            <span>DOPAG supplys components as well as highly automated 1K and 2K dispensing systems for </span>
            <ul>
                <li>greasing,</li>
                <li>bonding,</li>
                <li>sealing,</li>
                <li>potting and</li>
                <li>gasketing.</li>
            </ul>
        </div>
        <div className='start-page__start-btn-container'>
            <button
                onClick={() => setCurrentPage('home')}
            >
                Start
            </button>
        </div>
    </>

    const StartPageFR = <>
        <div className='start-page__title'>
            <h2>
                Choix de la bonne technologie de dosage adaptée à chaque application!
            </h2>
        </div>
        <div className='start-page__content'>
            <span>DOPAG fournit des composants ainsi que des systèmes de distribution 1K et 2K manuels et/ou hautement automatisés pour des applications</span>
            <ul>
                <li>de graissage, de lubrification,</li>
                <li>de distribution de colle, d'époxy, de silicone,</li>
                <li>de polyuréthane, de polysulfure,</li>
                <li>de produits d'étanchéité, </li>
                <li>de potting et</li>
                <li>de dépose de joint moussé in situ.</li>
            </ul>
        </div>
        <div className='start-page__start-btn-container'>
            <button
                onClick={() => setCurrentPage('home')}
            >
                {
                    langData['start'][language]
                }
            </button>
        </div>
    </>

    return (<div className={"start-page page" + (currentPage === 'start-page' ? ' show' : '')}>
        {
            language === 'de' ? StartPageDE : (language === 'en' ? StartPageEN : StartPageFR)
        }
    </div>);
}

import React from 'react';
import './_info-btn-list.scss';
import {langData} from "../../utils/language";
import VStore from "../../store";


export default function InfoBtnList() {

    const {language, setShowModal, currentPage} = VStore();

    const VetorMixBtnList = (
        <div className='info-btn-list'>
            <button
                onClick={() => setShowModal('TechnicalDataVectomix')}
            >
                {
                    langData['vectomix-btn-1'][language]
                }
            </button>
            <button
                onClick={() => setShowModal('FeatureAndBenefitsVectomix')}
            >
                {
                    langData['vectomix-btn-2'][language]
                }
            </button>
            <button
                onClick={() => setShowModal('ModuleVectomix')}
            >
                {
                    langData['vectomix-btn-3'][language]
                }
            </button>
        </div>
    );

    const EldomixBtnList = (
        <div className='info-btn-list'>
            <button
                onClick={() => setShowModal('TechnicalDataEldomix')}
            >
                {
                    langData['eldomix-btn-1'][language]
                }
            </button>
            <button
                onClick={() => setShowModal('FeatureAndBenefitsEldomix')}
            >
                {
                    langData['eldomix-btn-2'][language]
                }
            </button>
        </div>
    );

    const RotormixBtnList = (
        <div className='info-btn-list'>
            <button
                onClick={() => setShowModal('TechnicalDataRotormix')}
            >
                {
                    langData['rotormix-btn-1'][language]
                }
            </button>
            <button
                onClick={() => setShowModal('FeatureAndBenefitsRotormix')}
            >
                {
                    langData['rotormix-btn-2'][language]
                }
            </button>
            {/*<button
                onClick={() => setShowModal('OverviewRotormix')}
            >
                {
                    langData['rotormix-btn-3'][language]
                }
            </button>*/}
        </div>
    );

    return (<>
        {
            currentPage === 'vectomix' ? VetorMixBtnList : (
                currentPage === 'eldomix' ? EldomixBtnList : (
                    currentPage === 'rotormix' ? RotormixBtnList : ""
                )
            )
        }
    </>);
}

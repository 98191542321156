import React from 'react';
import './_modal.scss';
import VStore from "../../store";

export type ModalProps = {
    children?: React.ReactNode | string;
    show?: boolean;
}

export default function Modal({children, show}: ModalProps) {
    const {setShowModal} = VStore();
    function closeModal() {
        setShowModal(null);
    }

    return (<div className={"modal" + (show ? ' show' : '')}>
        <div className='modal__overlay' onClick={closeModal}></div>
        <div className='modal__dialog'>
            <button className='modal__close-btn' onClick={closeModal}>&times;</button>
            {children}
        </div>
    </div>);
}

import React from 'react';
import './_header.scss';
import VStore from "../../store";


export default function Header() {
    const {setCurrentPage} = VStore();

    return (<div className="header"
                 style={{
                     backgroundImage: `url(./assets/images/header_bg.png)`
                 }}
    >
        <div className="header__logo" onClick={() => setCurrentPage('home')}>
            <img src="./assets/images/dopag-logo.png" alt="Logo"/>
        </div>
    </div>);
}

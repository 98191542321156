import React from 'react';
import './_footer.scss';
import VStore from "../../store";

export default function Footer() {
    const {language} = VStore();

    const FooterDE = <div className="footer">
        Für mehr Informationen rund um unsere Produkte und Anwendungen besuchen Sie uns auf
        <a href="https://www.dopag.com/de-de/" target='_blank' rel='noreferrer'>https://www.dopag.com/de-de/</a>
    </div>

    const FooterEN = <div className="footer">
        For more information about our products and applications, visit us at
        <a href="https://www.dopag.com" target='_blank' rel='noreferrer'>https://www.dopag.com</a>
    </div>

    const FooterFR = <div className="footer">
        Pour plus d'informations sur nos produits et applications, visitez-nous sur
        <a href="https://www.dopag.com/fr-fr/" target='_blank' rel='noreferrer'>https://www.dopag.com/fr-fr/</a>
    </div>

    return (
        language === 'de' ? FooterDE :
            (language === 'en' ? FooterEN : FooterFR)
    );
}

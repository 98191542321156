import './_language-bar.scss';
import VStore from "../../store";

export default function LanguageBar() {
    const {language, setLanguage} = VStore();

    return (<div className="language-bar">
        <div className="language-bar__content">
            <button className={"language-bar__item" + (language === 'de' ? ' active' : '')}
                    onClick={() => setLanguage('de')}
            >
                <img src="./assets/images/germany-flag.svg" alt="Germany"/>
            </button>
            <button className={"language-bar__item" + (language === 'en' ? ' active' : '')}
                    onClick={() => setLanguage('en')}
            >
                <img src="./assets/images/uk-flag.svg" alt="UK"/>
            </button>
            <button className={"language-bar__item" + (language === 'fr' ? ' active' : '')}
                    onClick={() => setLanguage('fr')}
            >
                <img src="./assets/images/france-flag.svg" alt="France"/>
            </button>
        </div>
    </div>);
}
